import { Layout } from "@components";

import TutorialIndex from "@content/tutorial/using-dotter/index.fr";

export default function Tutorial() {
  return (
    <Layout title="Utiliser Dotter.science au quotidien">
      <TutorialIndex currenttuto="usingdotter" />
    </Layout>
  );
}
